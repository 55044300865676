@import "palette";

.pagination {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ul {
    li {
      h4 {
        border: 0;
        background-color: $white;
        color: $slateGrey;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;


        &:hover {
          background-color: transparent;
        }

        span {
          display: none;
        }
      }

      .Mui-selected {
        width: 42px;
        height: 42px;
        color: $white;
        border-radius: 16px;
        background: $purple;
        border: 0;

        &:hover {
          background-color: $purple;
        }
      }
    }
  }
}

