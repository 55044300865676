.stepper-arrow {
  position: absolute;
  top: 0;
  left: 12%;
}

.onboarding-step-content {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.step-icon {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid;
  box-sizing: border-box;

  &.symbol-unchecked {
    border: 1px solid var(--Gray-100, #DBDADD);
    background: #FFF;
  }

  &.symbol-checked {
    border: 1px solid var(--Core-Primary, #6749F4);
    background: var(--Core-Primary, #6749F4);
    cursor: pointer;
  }

  &.symbol-active {
    border: 1px solid var(--Core-Primary, #6749F4);
    background: #FFF;
  }

  .material-symbols-outlined {
    font-size: 16px;
    color: white;
  }
}


