.onboarding {
  position: relative;
  overflow-y: auto;

  .onboarding-box {
    height: 100%;

    .logo-header {
      position: absolute;
      top: 50px;
      left: 50px;
      right: 0;
    }
  }
}
