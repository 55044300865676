@import "../../../../../../palette";

.filter-popover {
  .MuiBackdrop-root {
    background-color: transparent;
  }

  .MuiPaper-root {
    background: $white;
    box-shadow: 0 4px 13px 0 rgba(97, 97, 97, 0.06);
    width: 380px;
    padding: 16px;
  }
}

.filter-popover-boolean {
  .MuiBackdrop-root {
    background-color: transparent;
  }

  .MuiPaper-root {
    border-radius: 16px;
    border: 1px solid $lightGrey1;
    background: $white;
    box-shadow: 0 4px 13px 0 rgba(97, 97, 97, 0.06);
    width: 160px;
    padding: 8px;
  }

  .filter-text-boolean {
    span {
      opacity: 0;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
      border-radius: 10px;
      background-color: $purpleLight;

      span {
        opacity: 1;
        color: $purple;
      }
    }
  }
}

.MuiAutocomplete-root.autocomplete-filter {
  width: 100%;
  border: 0;
  border-radius: 12px;
}

.autocomplete-filter-input.MuiOutlinedInput-root,
.autocomplete-filter-input .MuiOutlinedInput-root {
  padding: 0 !important;
}

.autocomplete-filter-input.MuiOutlinedInput-root .MuiSelect-select,
.autocomplete-filter-input .MuiOutlinedInput-root input {
  font: unset;
  border-radius: 12px;
  height: 34px;
  box-sizing: border-box;
  font-size: 14px;
  color: $purple;
  padding: 6px !important;

  &::placeholder {
    color: $purple;
    font-size: 14px;
  }

  &:focus, &[aria-expanded="false"] {
    border: 0;
  }
  &:focus, &[aria-expanded="true"] {
    border: 1px solid $purple;
    background-color: rgba(103, 73, 244, 0.05);
  }
}

.li-checkboxes-item {
  color: $dark;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding: 9px 12px;

  span {
    padding: 0;
  }

  .checkboxes-item {
    font-size: 18px;
    padding: 0 11px;

    &:hover {
      background-color: transparent;
    }

    svg {
      color: $lightGrey2;
      margin-right: 0;
    }
  }
}
