@import "../../../../palette";

.select-table {
  .MuiSelect-select {
    border-radius: 12px;
    border: 1px solid $lightGrey1;
    background: transparent;
    box-shadow: 0 4px 13px 0 rgba(97, 97, 97, 0.02);
    padding: 6px 10px 6px 16px;
    z-index: 9;

    &:checked span {
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  span {
    position: absolute;
    right: 5px;
    font-size: 20px;
    z-index: 1;
  }
}

.rotated-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}


.select-dropdown {
  border-radius: 16px;
  border: 1px solid $lightGrey1;
  background: $white;
  box-shadow: 0 4px 13px 0 rgba(97, 97, 97, 0.02);

  ul {
    padding: 0;

    li {
      color: $dark;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      padding: 5px 10px 5px 10px !important;
    }
  }
}

