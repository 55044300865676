@import "../../../../../../palette";

.measures-selected {
  border-radius: 16px;
  border: 1px solid $lightGrey1;
  padding: 12px 16px;
}

.alias-input {
  margin: 0 0 0 5px;
  input {
    border: 0;
    padding: 0 0 0 5px;

    &:focus {
      border: 0;
    }
  }
}
