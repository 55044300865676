@import "../../palette";

.MuiAutocomplete-root.branch-select-component {
  color: $black;
  margin-left: 40px;
  display: flex;
  align-items: center;

  .MuiInputBase-root {
    &:before, &:after {
      display: none;
    }
  }

  .branch-option {
    min-width: 300px;
    width: max-content;

    .MuiInput-root {
      font-size: 16px;
      color: $black;

      .MuiInputBase-input {
        background-color: transparent;
      }

    }

    .MuiSvgIcon-root {
      display: none;
    }
  }
}

.active .material-symbols-outlined {
  color: #6749F4;
}


