@import "palette";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  background-color: $lightestGrey;
  padding-right: 0 !important;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.flex-box-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-box-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-box-col {
  display: flex;
  flex-direction: column;
}

.flex-box-col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-box-col-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-box-col-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.flex-box-center-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-box-start-space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-box-start-center{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-box-row {
  display: flex;
  flex-direction: row;
}

.flex-box-align-center {
  display: flex;
  align-items: center;
}

.flex-box-align-start {
  display: flex;
  align-items: flex-start;
}

.flex-box-start {
  display: flex;
  justify-content: flex-start;
}

.flex-box-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-box-end {
  display: flex;
  justify-content: flex-end;
}

.MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell {
  overflow: visible !important;
  padding: 16px 24px;
}

.MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader{
  padding: 0 24px;
}

.MuiDataGrid-virtualScrollerContent {
  border-left: 1px solid $lightGrey3;
  border-right: 1px solid $lightGrey3;
  border-bottom: 1px solid $lightGrey3;
  box-sizing: border-box;
}

.MuiDataGrid-columnHeaderTitle {
  text-transform: capitalize;
  color: $slateGrey;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.24px;
}

.MuiDataGrid-row.Mui-selected {
  background-color: transparent !important;
}

.MuiDataGrid-row:hover {
  background-color: transparent !important;
}

.MuiDataGrid-main, .MuiDataGrid-virtualScroller {
  overflow: visible !important;
}

.MuiAutocomplete-clearIndicator {
  visibility: hidden;
  display: none;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within {
  outline: unset !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon {
  opacity: 1 !important;
}

.MuiDataGrid-iconButtonContainer {
  visibility: visible !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

.rotate-edit {
  transform: rotate(280deg);
}

.autocomplete-container {

  .MuiAutocomplete-root {
    border: 0;
    display: flex;
  }

  .autocomplete-select {
    color: #1B1A1C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    .MuiInputBase-root {
      padding: 0 !important;
    }

    input {
      font-size: 14px;
      border-radius: 5px;
      border: 1px solid var(--Gray-50, #E9E9E9);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.03);
      padding: 1px 0 1px 8px !important;

      &::placeholder {
        color: #1B1A1C;
        opacity: 1;
      }
    }
  }
}

.MuiAutocomplete-endAdornment {
  svg {
    display: none;
  }
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.MuiDataGrid-sortIcon {
  width: 14px !important;
  height: 14px !important;
  color: $slateGrey;
}

.MuiOutlinedInput-root {
  &.Mui-error .MuiOutlinedInput-input {
    border-color: $warning;
  }

  .MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 16px;
  }

}

.material-symbols-outlined {
  font-size: 20px;
}

.container-modal {
  overflow-y: auto;
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  background-color: $white;
  border-radius: 20px;
  border: 1px solid var(--Gray-50, #E9E9E9);
  box-shadow: 10px 4px 24px 0 rgba(140, 140, 140, 0.06);
}

.container-modal-small {
  max-height: 650px;
  max-width: 658px;
  width: 100%;
  border-radius: 20px;
  background: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;

  .modal-close {
    position: absolute;
    top: 28px;
    right: 0;
    font-size: 24px;
    margin: 0;
    padding: 0;

    .modal-close-icon {
      font-size: 24px;
    }
  }
}

.container-modal-sx-custom {
  max-height: 569px;
  max-width: 380px;
  overflow-y: auto;
  left: 37%;
  box-sizing: border-box;
  border: 1px solid $lightGrey1;
  padding: 16px;
}

.container-modal-bg .MuiBackdrop-root {
  background-color: rgba(27, 26, 28, .5);
}

.container-modal-bg-none .MuiBackdrop-root {
  background-color: transparent;
}

.select-configuration .MuiPaper-root {
  border-radius: 16px;
}

.select-configuration .MuiOutlinedInput-root {
  border: 0;
  padding: 0 !important;
}

.select-configuration .MuiOutlinedInput-root .MuiAutocomplete-input {
  border: 0;
  padding: 16px;
}

.select-configuration .MuiOutlinedInput-input {
  border: 0;
}

.select-configuration .MuiOutlinedInput-input:focus {
  border: 0;
}

.MuiSelect-iconOutlined {
  top: calc(50% - .7em) !important;
}

.search-input .MuiInputBase-root {
  padding-left: 0;
}

.search-input .MuiInputBase-input {
  background-color: transparent;
  border: 0;
  padding: 13px 16px 13px 41px;
}

.select-search-icon {
  position: absolute;
  left: 15px;

  span {
    margin: 0;
  }
}

.MuiListSubheader-root {
  position: relative !important;
}

.MuiSelect-select .select-placeholder {
  color: $grey;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.MuiBox-root .filter-tags{
  height: 22px;
  border-radius: 5px;
  border: 1px solid $lightGrey3;
  background: $lightestGrey5;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.03);
  color: $slateGrey;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  .MuiChip-label{
    padding: 0 6px 0 8px;
  }
}

.data-pick-focused input{
  border-radius: 12px;
  border: 1px solid $purple !important;
  background: rgba(103, 73, 244, 0.05);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-number{
  width: 100%;
  button{
    display: none;
  }
  input{
    width: 100%;
    border: 1px solid transparent;
    border-radius: 12px;
    height: 34px;
    box-sizing: border-box;
    padding: 6px;
    color: $purple;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    &::placeholder{
      color: $purple;
    }
    &:focus{
      background-color: rgba(103, 73, 244, 0.05);
    }
    &:focus-visible{
      outline: none;
    }
  }
}
.MuiButton-colorWarning{
  background-color: $warning !important;
}