$dark: #1B1A1C;
$purple: #6749F4;
$purpleLight: #F7F6FE;
$white: #FFFFFF;
$lightGrey1: #DBDADD;
$lightGrey2: #BCBABF;
$lightGrey3: #E9E9E9;
$grey: #78737D;
$grey1: #787878;
$darkGrey: #3C3B3F;
$black: #000000;
$lightestGrey: #F7F7F7;
$lightestGrey1: #FAFAFA;
$softGrey: #CAC4D0;
$slateGrey: #524E56;
$brightGreen: #33cf4d;
$slateBlue: #79747E;
$lightestGrey2: #F3F3F3;
$lightestGrey3: #E9E9EA;
$lightestGrey4: #39393D;
$lightestGrey5: #F5F5F5;
$brightBlue: #007bff;
$warning: #F44949;
$mediumGrey: #6D6D6D;


:export {
  dark: $dark;
  purple: $purple;
  white: $white;
  lightGrey1: $lightGrey1;
  lightGrey2: $lightGrey2;
  lightGrey3: $lightGrey3;
  grey: $grey;
  grey1: $grey1;
  darkGrey: $darkGrey;
  black: $black;
  lightestGrey: $lightestGrey;
  lightestGrey1: $lightestGrey1;
  softGrey: $softGrey;
  slateGrey: $slateGrey;
  brightGreen: $brightGreen;
  slateBlue: $slateBlue;
  lightestGrey2: $lightestGrey2;
  lightestGrey3: $lightestGrey3;
  lightestGrey4: $lightestGrey4;
  lightestGrey5: $lightestGrey5;
  brightBlue: $brightBlue;
  mediumGrey: $mediumGrey;
  purpleLight: $purpleLight;
  warning: $warning;
}

