@import "palette";

.modal-data {
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 16px;

  &:hover {
    background-color: $lightestGrey5;
  }

  .modal-icon-data {
    font-size: 20px;
    margin-right: 8px;
  }
}

.select-button-back {
  .material-symbols-outlined {
    font-size: 24px;
  }

  position: absolute !important;
  min-width: auto !important;
  top: 26px;
  left: 24px;
  margin: 0 !important;
  padding: 0 !important;
}

.select-text-step {
  border-radius: 12px;
  border: 1px solid $lightGrey1;
  background: $lightestGrey5;
  padding: 8px 16px;

  .modal-icon-data {
    font-size: 14px;
    margin-right: 6px;
  }
}
