@import "palette";

.entity-data-grid {
  border-color: transparent !important;

  .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor {
    border-color: transparent;
  }

  .MuiDataGrid-cell .tag-cell {
    border-radius: 5px;
    border: 1px solid $lightGrey3;
    background-color: $lightestGrey5;
    width: fit-content;
    height: 22px;

    .MuiChip-label {
      font-size: 14px;
      font-weight: 500;
      color: #524E56;
      line-height: 22px;
    }
  }

  .MuiDataGrid-container--top [role=row], .MuiDataGrid-container--bottom [role=row] {
    background: var(--Gray-25, #F5F5F5) !important;
    border-radius: 16px 16px 0 0 !important;
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  .MuiDataGrid-row {
    color: $dark;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    span {
      color: $grey;
    }
  }
}

.MuiDataGrid-root {
  .MuiDataGrid-cell:focus {
    outline: 0 !important;
  }

  .MuiDataGrid-columnHeader {
    &:focus {
      outline: 0 !important;
    }

    &:hover .MuiDataGrid-menuIcon, .MuiDataGrid-columnSeparator {
      visibility: hidden !important;
    }
  }
}

