@import "palette";

.container-modal-small-custom {
  max-height: 540px;
  overflow-y: auto;
  top: 40%;
  box-sizing: border-box;
  border: 1px solid $lightGrey1;
}

.measures-input {
  width: 100%;

  .MuiInputBase-root {
    padding: 0;
  }
}