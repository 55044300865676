@import '../../../../palette';

.MuiChip-outlined.asset-title-chip {
  border-radius: 5px;
  border: 1px solid $lightGrey1;
  background-color: $lightestGrey5;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.03);
  height: initial;

  .MuiChip-label {
    font-size: 14px;
    font-weight: 500;
    color: $slateGrey;
    line-height: 22px;
    padding: 0 8px;
  }
}

