.info-dim-table {
  box-sizing: border-box;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #DBDADD;
  background: #F5F5F5;
  padding: 16px;
}

.entity-data-grid {
  .MuiDataGrid-virtualScrollerContent {
    border-radius: 0 0 16px 16px;
  }
}

.MuiDataGrid-root .MuiDataGrid-cell {
  display: flex;
  align-items: center;
}

.entity-data-grid .MuiAutocomplete-popper {
  z-index: 1400;
}

.entity-data-grid .MuiDataGrid-root {
  overflow: visible;
}
