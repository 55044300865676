@import "../../../palette";

.add-features-popover {
  .MuiPaper-root {
    border-radius: 16px;
    border: 1px solid $lightGrey1;
    box-shadow: 0 4px 13px 0 rgba(97, 97, 97, 0.02);
    margin-top: 10px;
  }


  .add-features-popover-box {
    max-width: 454px;
    max-height: 340px;
    padding: 12px;
  }
}
