.asset-select {
  .MuiSelect-select {
    border-radius: 10px;
    padding: 6px 36px 6px 12px;

    .MuiMenuItem-root:hover{
      background-color: red !important;
    }
  }
  svg{
    right: 12px;
    margin: 2px 0 0 0;
  }
}

.asset-select-item:hover{
  border-radius: 10px;
  background: var(--Gray-100, #F5F5F5) !important;
}


