@font-face {
    font-family: 'BlinkMacSystemFont Medium';
    src: url('assets/fonts/blinkmacsystemfont-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BlinkMacSystemFont Semibold';
    src: url('assets/fonts/blinkmacsystemfont-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
