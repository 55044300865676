@import '../../../palette';

.link-no-decoration {
  text-decoration: none;
  color: $dark;
}

.MuiDataGrid-row:hover .link-no-decoration {
  .underline {
    text-decoration: underline;
  }
}

.data-asset-cell-text {
  color: $slateGrey;
  font-size: 14px;
}
