@import "../../palette";

.features-popover {
  border-radius: 16px;
  border: 1px solid $lightGrey1;
  box-shadow: 0 4px 13px 0 rgba(97, 97, 97, 0.02);
  padding: 4px;

  .MuiBackdrop-root {
    background-color: transparent;
  }
}
