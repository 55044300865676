.onboarding-step-connect-box {
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--Gray-50, #E9E9E9);
  background: #FFFFFF;
  box-shadow: 0 4px 13px 0 rgba(97, 97, 97, 0.02);
}


.button-date {
  position: relative;
  border-radius: 12px;
  border: 1px solid #DBDADD;
  background: #FFF;
  box-shadow: 0 4px 13px 0 rgba(97, 97, 97, 0.02);
  color: transparent;
  margin: 0 10px !important;
  padding: 12px 16px;

  .MuiRadio-root {
    color: transparent;
    width: 0;
    padding: 0;
  }

  .MuiRadio-root.Mui-checked {
    position: absolute;
    left: 0;
    color: transparent;
    border-radius: 12px;
    border: 2px solid #1B1A1C;
    width: 100%;
    height: 100%;
  }
}

svg {
  margin-right: 8px;
}


.input-password {
  position: absolute;
  right: 16px;
}

